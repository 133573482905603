import {EVENT_FILTER_TYPE, RECURRING_FILTER, WIDGET_TYPE} from '@wix/wix-events-commons-statics'
import {StatusFilter} from '@wix/events-types'
import {userLiveVideoStartRecordVideo} from '@wix/bi-logger-events-users/v2'
import {OwnerLogger} from '@wix/yoshi-flow-editor'
import {createAsyncAction} from '../services/redux-toolkit'
import {WidgetSettingsState} from '../reducers'
import {getEventIdsByStatusFilter, getUpcomingEvents} from '../selectors/events'
import {getEventFilterType} from '../reducers/component-config'
import {updateComponentDraft} from './component-config'
import {resize} from './widget-visibility'
import {setActiveEvent} from './events'

interface SetEventsWidgetTypeParams {
  newWidgetType: WIDGET_TYPE
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

const DEFAULT_LIST_WIDGET_EVENT_COUNT = 6

export const setEventsWidgetType = createAsyncAction<void, SetEventsWidgetTypeParams>(
  'SET_EVENTS_WIDGET_TYPE',
  async ({newWidgetType, multiEventWidget, calendar, recurringFilter}, {getState, dispatch, extra: {bi}}) => {
    const state = getState()
    const events = getState().events
    const upcomingEvents = getUpcomingEvents({state, multiEventWidget, calendar, recurringFilter})
    const singleEventWidget = newWidgetType === WIDGET_TYPE.SINGLE
    const event = events.all[0]
    let eventId

    editorSettingsMyEventsShowSetup({
      state: getState(),
      bi,
      singleEventWidget,
      recurringFilter,
    })

    if (singleEventWidget) {
      eventId = event ? [event.id] : []
    } else {
      eventId = upcomingEvents.map(item => item.id).slice(0, DEFAULT_LIST_WIDGET_EVENT_COUNT)
    }

    await dispatch(resize())

    if (eventId.length) {
      await dispatch(updateComponentDraft({byEventId: {eventId}}))

      if (singleEventWidget) {
        dispatch(setActiveEvent({event, singleEventWidget, recurringFilter}))
      }
    }
  },
)

interface SetEventFilterTypeParams {
  filterType: EVENT_FILTER_TYPE
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const setEventFilterType = createAsyncAction<void, SetEventFilterTypeParams>(
  'SET_EVENT_FILTER_TYPE',
  async ({filterType, multiEventWidget, calendar, recurringFilter}, {getState, dispatch, extra: {bi}}) => {
    const state = getState()
    const byStatus = getComponentConfigStatus(filterType)
    const byEventId = getComponentConfigEventId({
      state: getState(),
      filterType,
      multiEventWidget,
      calendar,
      recurringFilter,
    })
    const byCategoryId = state.componentConfig.byCategoryId

    editorSettingsMyEventsShowSetup({
      state: getState(),
      bi,
      singleEventWidget: !multiEventWidget,
      recurringFilter,
    })

    await dispatch(
      updateComponentDraft(
        filterType !== EVENT_FILTER_TYPE.MANUAL && byCategoryId
          ? {byCategoryId: {categoryId: byCategoryId.categoryId, statusFilter: getStatusFilter(filterType)}}
          : {
              byStatus,
              byEventId,
            },
      ),
    )
  },
)

interface SetCategoryFilterParams {
  categoryId: string
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const setCategoryFilter = createAsyncAction<void, SetCategoryFilterParams>(
  'SET_CATEGORY_FILTER',
  async ({categoryId, calendar, multiEventWidget, recurringFilter}, {getState, dispatch, extra: {bi}}) => {
    const state = getState()
    const filterType = getEventFilterType(state)
    const byStatus = getComponentConfigStatus(filterType)
    const byEventId = getComponentConfigEventId({state, filterType, calendar, multiEventWidget, recurringFilter})

    editorSettingsMyEventsShowSetup({
      state: getState(),
      bi,
      singleEventWidget: !multiEventWidget,
      recurringFilter,
      categoryId,
    })

    await dispatch(
      updateComponentDraft(
        categoryId
          ? {
              byCategoryId: {categoryId: [categoryId], statusFilter: getStatusFilter(filterType)},
            }
          : {byStatus, byEventId},
      ),
    )
  },
)

interface SetRecurringFilterParams {
  recurringFilter: RECURRING_FILTER
  singleEventWidget: boolean
}

export const setRecurringFilter = createAsyncAction<void, SetRecurringFilterParams>(
  'SET_RECURRING_FILTER',
  async ({recurringFilter, singleEventWidget}, {getState, extra: {bi}}) =>
    editorSettingsMyEventsShowSetup({
      state: getState(),
      bi,
      singleEventWidget,
      recurringFilter,
    }),
)

const getStatusFilter = (filterType: EVENT_FILTER_TYPE): StatusFilter => {
  const filterMap = {
    [EVENT_FILTER_TYPE.UPCOMING_AND_PAST]: StatusFilter.FUTURE_AND_PAST,
    [EVENT_FILTER_TYPE.PAST]: StatusFilter.PAST_ONLY,
    [EVENT_FILTER_TYPE.UPCOMING]: StatusFilter.FUTURE_ONLY,
  }

  return filterMap[filterType] ?? StatusFilter.FUTURE_ONLY
}

const getComponentConfigStatus = (filterType: EVENT_FILTER_TYPE): wix.events.editor.ByStatus => {
  const isStatic = filterType === EVENT_FILTER_TYPE.MANUAL
  const filter = getStatusFilter(filterType)

  return isStatic ? undefined : {filter}
}

interface GetComponentConfigByEventIdParams {
  state: WidgetSettingsState
  filterType: EVENT_FILTER_TYPE
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

const getComponentConfigEventId = ({
  state,
  filterType,
  multiEventWidget,
  calendar,
  recurringFilter,
}: GetComponentConfigByEventIdParams) => {
  const isStatic = filterType === EVENT_FILTER_TYPE.MANUAL
  const filter = getStatusFilter(filterType)
  const eventId = getEventIdsByStatusFilter({state, filter, multiEventWidget, calendar, recurringFilter})

  return isStatic ? {eventId} : undefined
}

interface EditorSettingsMyEventsShowSetupsParams {
  state: WidgetSettingsState
  singleEventWidget: boolean
  recurringFilter: number
  eventId?: string
  categoryId?: string
  bi: OwnerLogger
}

export const editorSettingsMyEventsShowSetup = ({
  state,
  singleEventWidget,
  eventId,
  categoryId,
  bi,
  recurringFilter,
}: EditorSettingsMyEventsShowSetupsParams) => {
  let category_id =
    categoryId ?? state.componentConfig.byCategoryId ? state.componentConfig.byCategoryId.categoryId[0] : null
  const recurring_shown = recurringFilter === RECURRING_FILTER.ALL ? 'individual' : 'one'
  const event_id = eventId ?? singleEventWidget ? state.events.active?.id : null

  if (singleEventWidget) {
    category_id = null
  }

  bi.report(
    userLiveVideoStartRecordVideo({
      buttonStatus: singleEventWidget ? 'single' : 'multiple',
      category_id,
      event_id,
      recurring_shown,
      events_shown: getEventsShown(state),
    }),
  )
}

const getEventsShown = (state: WidgetSettingsState) => {
  const statusFilter = state.componentConfig.byCategoryId
    ? state.componentConfig.byCategoryId.statusFilter
    : state.componentConfig.byStatus?.filter

  if (statusFilter === StatusFilter.FUTURE_AND_PAST) {
    return 'upcoming_past'
  }

  if (statusFilter === StatusFilter.FUTURE_ONLY) {
    return 'upcoming'
  }

  if (statusFilter === StatusFilter.PAST_ONLY) {
    return 'past'
  }

  return 'selected_manually'
}
